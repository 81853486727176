* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url("../assets/images/wallpaper.svg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // url(<path-to-image>) lightgray 50% / cover no-repeat ,
  background-image: linear-gradient(180deg, #f5f5f5 0%, #f2ebf5 100%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: lightgray;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/images/wallpaper2.svg");
  background-size: cover;
  background-position: center;
  opacity: 0.1; /* Adjust opacity */
  z-index: -1; /* Place it behind the content */
}
.div-white-r24-shadows {
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.div-white-shadows {
  background-color: #fff;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.p-16-black-700 {
  margin: 0;
  color: #1f2025;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.p-16-black-400 {
  margin: 0;
  color: #1f2025;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
