.employees-page-header {
  padding: 24px;
  border-radius: 20px;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.employees-header-number {
  color: var(--Purple-2);
  line-height: 40px;
}

.employees-table {
  padding: 24px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.employees-table-content {
  max-height: 560px;
  overflow-y: auto;
}

.employees-table-content::-webkit-scrollbar {
  display: none;
}

.employees-table-search {
  border: 1px solid var(--Gray-2);
  height: 100% !important;
  padding: 11px;
  width: 100% !important;
}

.employees-table-add-btn {
  width: 172px !important;
}

.employees-row {
  padding: 8px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.employees-row-picture-name {
  width: 25%;
}

.employees-row-data {
  width: calc(75% - 48px);
}

.employees-row-picture-name > div {
  width: calc(100% - 48px);
}

.employees-row-value {
  width: calc(25% - 48px);
}

.employees-row-value p,
.employees-row-picture-name p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@media (max-width: 1299px) {
  .employees-row {
    flex-direction: column;
    gap: 24px;
  }

  .employees-row-picture-name {
    width: 100%;
  }

  .employees-row-data {
    width: 100%;
    gap: 12px;
  }
  .employees-row-value {
    width: calc(25% - 8px);
  }
}

@media (max-width: 899px) {
  .employees-table-btn-search {
    flex-direction: column-reverse;
  }
  .employees-header-content {
    flex-direction: column;
  }
  .employees-header-content > div {
    justify-content: start;
  }
  .employees-header-number {
    line-height: 35px;
    font-size: 24px;
  }
  .employees-header-text {
    font-size: 12px;
    text-align: center;
    flex: 1;
  }
  .employees-header-progress-bar {
    display: none;
  }
}

@media (max-width: 749px) {
  .employees-row-data {
    flex-direction: column;
  }
  .employees-row-value {
    width: 80%;
  }
}
