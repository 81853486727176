.RemainingDaysProgressBarOuter {
  width: 100%;
  display: flex;
  height: 36px;
  align-items: center;
  gap: 12px;
  color: white;
  align-self: stretch;

  border-radius: 12px;
}
.RemainingDaysProgressBarInner {
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;

  border-radius: 12px;

  font-family: "Outfit";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  animation: ProgressBarAnimation 2s ease forwards;
  background-color: var(--Purple-2);
}
.RemainingDaysProgressBarInner-second {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 12px;

  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  animation: ProgressBarAnimation2 2s ease forwards;
  background-color: var(--Purple-8);
}
@keyframes ProgressBarAnimation {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ProgressBarAnimation2 {
  from {
    opacity: 0;
    width: 0;
    transform: scaleX(-1);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
