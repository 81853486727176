.dolazak {
  background-color: var(--Green);
}
.odlazak {
  background-color: var(--Red);
}
.pauza {
  background-color: var(--blue);
}
.rad-od-kuce {
  background-color: var(--Yellow);
}
.privatan-izlazak {
  background-color: var(--Purple-5);
}
.sluzbeni-izlazak {
  background-color: var(--Purple-2);
}
p {
  margin: 0;
}
.homepage-main {
  padding-right: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  scrollbar-width: none;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
}

.homepage-main::-webkit-scrollbar {
  display: none;
}

.header-component {
  display: flex;
  padding: 24px 72px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.header-items {
  display: flex;
  gap: 18px;
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-text p {
  margin: 0;
  color: #1f2025;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}

.time-components {
  width: 100%;
  display: flex;
  gap: 24px;
}

.time-buttons {
  width: 387px;
  height: 501px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-items: center;
  align-items: center;
  grid-template-columns: 50% 50%;
}

.time-table {
  width: 72%;
  height: 501px;
  padding: 24px;
}

.time-button-main {
  width: 100%;
  height: 120px;
  border-radius: 16px;
  transition: border-radius 0.3s ease;
  cursor: pointer;
  transition: 0.3s all;
}

.time-button-main:hover {
  transform: scale(1.05);
}

.time-button-central {
  height: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.timetable-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.timetable-header {
  display: flex;
  padding: 0 24px;
  width: calc(100% - 100px);
}

.timetable-header-text {
  display: flex;
  height: 20px;
  gap: 8px;
  align-items: center;
  flex: 1;
}

.timetable-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  max-height: 410px;
  overflow-y: auto;
  scrollbar-width: none;
}

.timetable-content::-webkit-scrollbar {
  display: none;
}

.timetable-row {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  margin: 4px 0 0 2px;
  border-radius: 8px;
  flex-direction: row;
  width: calc(100% - 12px);

  .timetable-row-inside {
    width: calc(100% - 100px);
    display: flex;
    gap: 10px;
  }

  p {
    margin: 0;
    max-height: 20px !important;
    overflow: hidden;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
}

.daily-graph {
  display: flex;
  flex-direction: column;
}

.daily-graph-header {
  width: 100%;
  display: flex;
  padding: 24px 24px 0 24px;
  justify-content: space-between;
}

.daily-graph-header-legend {
  display: flex;
  gap: 24px;
}

.daily-graph-container {
  display: flex;
  justify-content: center;
}

.avg-graph-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 32px;
}

.avg-graph {
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.notifications-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  padding: 24px 18px;
  justify-content: space-between;
  align-items: center;
  max-height: 550px;
}

.notifications-img {
  height: 40px;
  width: 40px;
  padding: 10px;
  border-radius: 100px;
}

.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-header-row {
  display: flex;
  gap: 10px;
  align-items: center;
}
.notifications-btn {
  max-width: 356px;
}
.daily-graph-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time-table-row.clicked {
  background-color: rgb(233, 233, 233) !important;
}

.daily-graph-container {
  width: 98%;
  max-width: 1100px;

  @media (max-width: 1399px) {
    max-width: 1020px;
  }

  div[options] {
    width: 100%;
    #apexchartsxmtd9azv {
      width: 100% !important;

      & svg {
        width: 100%;

        & foreignObject {
          width: 100%;
        }
        #SvgjsG1377 {
          width: 100% !important;
        }
      }
    }
  }
}
.add-notification {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 15;
}
.notification-content {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.notification {
  padding: 5px 0;

  .notification-image {
    padding: 6px;
  }

  img {
    border-radius: 28px;
    background-color: #d9d9d9;
  }
  p {
    flex: 1;
    max-height: 20px;
    overflow: hidden;
  }

  & .notification-text {
    & > p {
      max-height: 16px;
    }

    .date-title {
      & p:nth-child(2) {
        text-align: end;
      }
    }
  }
}
.arrow-down {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}
.arrow-up {
  transform: rotate(0);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 1200px) {
  .avg-graph {
    width: 600px;
  }
}
@media (max-width: 1023px) {
  g {
    height: 30px !important;
  }
  .homepage-main {
    padding-right: 0;
  }
  .div-white-r24-shadows {
    border-radius: 18px !important;
  }
  .header-component {
    padding: 18px 20px;
  }
  .header-items {
    align-items: center;
  }
  .header-items img {
    width: 24px;
    height: 22px;
  }
  .home-header-term {
    font-size: 14px !important;
  }
  .home-header-value {
    font-size: 12px !important;
    color: #a96aff;
  }
  .time-buttons {
    width: 337px;
    padding: 18px;
  }
  .time-button-central {
    padding: 18px 20px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // flex-grow: 1;
      margin-right: 10px;
      font-size: 14px;
    }
  }
  .time-table {
    padding: 18px;
  }
  .timetable-content {
    max-height: 420px;
  }
  .timetable-header {
    padding: 0 12px;
    gap: 8px;
    p {
      font-size: 14px;
      width: 65px !important;
      flex: 1;
      overflow: hidden;
    }
  }
  .timetable-row {
    padding: 16px 12px;
    width: calc(100% - 6px);
  }
  .timetable-row-inside {
    p {
      width: 70px !important;
      font-size: 12px;
    }
  }
  .daily-graph-header {
    p {
      font-size: 14px;
    }
  }
  .avg-graph {
    width: 480px;
    padding: 16px 18px 12px 18px;
  }
  .avg-graph-header {
    & p:nth-child(1) {
      font-size: 14px;
    }
    & p:nth-child(2) {
      font-size: 26px !important;
    }
  }
  .notifications-container {
    padding: 24px 18px;
    width: 320px;
    max-height: 500px;
  }
  .avg-graph-btn {
    width: 100% !important;
  }
}
@media (max-width: 900px) {
  .avg-graph {
    width: 380px;
  }
  .time-components {
    flex-direction: column;

    .time-buttons {
      width: 100%;
    }
    .time-table {
      width: 100%;
    }
    .avg-graph {
      width: 100%;
    }
  }
  .daily-graph-container-wrapper {
    padding: 0 2px;
    justify-content: left;

    &::-webkit-scrollbar {
      background-color: rgb(233, 233, 233);
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204);
      border-radius: 10px;
    }
  }
  .daily-graph-container {
    // min-width: 120% !important;
  }
  .notifications-container {
    width: 100%;
  }
  .time-button-main:hover {
    transform: scale(1);
  }
}
@media (max-width: 600px) {
  .header-component {
    padding: 18px;
  }
  .header-items {
    flex-direction: column;
  }
  .header-text {
    align-items: center;
  }
  .header-items img {
    width: 18px;
    height: 16px;
  }
  .daily-graph-header {
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .timetable-header {
    width: calc(100% - 50px);
    gap: 4px;
  }
  .timetable-row {
    span {
      width: 30px !important;
    }
  }
  .timetable-row .timetable-row-inside {
    width: calc(100% - 50px);

    span {
      width: 30px !important;
    }
  }
  .daily-graph-header p {
    font-size: 12px;
  }
}
@media (max-width: 350px) {
  .timetable-header p {
    width: 40px !important;
  }
  .avg-graph-buttons {
    flex-direction: column;
  }
}
