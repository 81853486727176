p {
  margin: 0;
}
.homepage-main {
  .contracts-graphic {
  }
}
.home-header-value {
  font-size: 16px !important;
  color: #a96aff !important;
}
.header-statistic {
  .presence {
    height: 176px;
    border-radius: 12px;
    padding: 16px;
    flex: 1;
  }
}
.notification-requests {
  max-width: 386px;
  .requests {
    padding: 24px 16px;
    border-radius: 24px;

    box-shadow: 3px 5px 10px -2px rgba(13, 10, 44, 0.15);

    .requests-container {
      height: 222px;
      overflow: hidden;
    }
  }
  .request {
    .picture {
      border-radius: 64.583px;
      border: 0.646px solid #883cef;
    }
    p {
      line-height: 20px;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #696969;
    opacity: 0.11;
  }
}
.my-absence {
  p {
    line-height: 20px;

    &.date {
      line-height: 16px;
    }
  }
  .absence {
    padding: 7px;
  }

  padding: 24px 18px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);

  .line {
    border-bottom: 2px dashed black;
  }
}

.expiring-contracts {
  padding: 24px;
  border-radius: 24px;
  box-shadow: 3px 5px 10px -2px rgba(13, 10, 44, 0.15);

  p {
    line-height: 20px;
  }

  .expiring-contracts-table {
    .table-header {
      border-radius: 12px;
      padding: 11px 0;
      p {
        padding: 7px 0;
        flex: 1;
        text-align: center;
      }
    }
    .table-content {
      height: 220px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .expiring-contracts-table-row {
      padding: 10px 0 20px 0;
      border-bottom: 1px solid rgba(129, 129, 129, 0.11);

      img {
        border-radius: 26px;
        border: 1px solid var(--Purple-2);
      }
      p {
        line-height: 18px;
        text-align: center;
      }
      & div {
        flex: 1;
        p {
          width: 50px;
          height: 20px;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.avg-graph-admin {
  border-radius: 24px;
  padding: 24px;
  width: 858px;
}

@media (max-width: 1435px) {
  .header-statistic-graph {
    flex-direction: column;
    align-items: start;
    gap: 2px;
  }
}
@media (max-width: 1380px) {
  .avg-graph-admin {
    width: 800px;
  }
}
@media (max-width: 1435px) {
  .header-statistic-graph {
    & > p {
      font-size: 10px;
    }
  }
}

@media (max-width: 1023px) {
  .my-absence,
  .notification-requests {
    max-width: 320px;
  }
}
@media (max-width: 900px) {
  .homepage-content {
    flex-direction: column;
  }
  .my-absence,
  .notification-requests {
    max-width: 100%;
    align-items: center;
  }
  .header-statistic-number {
    & p:nth-child(1) {
      font-size: 14px;
      line-height: 18px;
    }
    & p:nth-child(2) {
      font-size: 26px;
      line-height: 37px;
    }
  }
  .expiring-contracts-title {
    font-size: 14px;
    line-height: 18px;
  }

  .expiring-contracts-see-more {
    height: 52px !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .expiring-contracts {
    .expiring-contracts-table {
      .table-header {
        padding: 5px 0;
      }
    }

    .expiring-contracts-table-row {
      padding: 5px 0 10px 0 !important;
    }
    .table-content {
      height: 166px !important;
    }
  }
  .expiring-contracts-table p {
    font-size: 12px;
    line-height: 18px;
  }
  .my-absence {
    p {
      font-size: 12px;
      line-height: 16px;
    }
    .absence-type {
      font-size: 14px;
      line-height: 20px;
    }
    .my-absence-btn {
      height: 52px !important;
      max-width: 170px;
      p {
        font-size: 14px !important;
      }
    }
  }
}
@media (max-width: 599px) {
  .header-statistic {
    gap: 12px;
  }
  .expiring-contracts {
    .expiring-contracts-table {
      .table-header {
        & p:nth-child(2),
        p:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .expiring-contracts-table-row {
    & div:nth-child(2),
    div:nth-child(3) {
      display: none;
    }
  }
  .my-absence {
    .my-absence-btn {
      max-width: 100%;
    }
  }
  .expiring-contracts {
    .title-btn {
      flex-direction: column;
      gap: 12px;

      .expiring-contracts-see-more {
        width: 100% !important;
      }
    }
  }
  .avg-graph-admin {
    padding: 16px 8px;
  }
}
@media (max-width: 370px) {
  .header-statistic {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}
