.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmation-modal-content {
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  background: #fff;

  /* Shadow-3 */
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.25);
}
.confirmation-modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.confirmation-modal-buttons button {
  cursor: pointer;
  border: none;
  min-width: 100px;
}
.confirmation-modal-no {
  border-radius: 12px;
  border: 1px solid #883cef !important;
  color: #883cef;
  display: flex;
  height: 52px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.confirmation-modal-yes {
  display: flex;
  height: 52px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 12px;
  background: #883cef;
  color: #fff;
}
