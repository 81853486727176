.modal {
  padding: 20px;
  border-radius: 10px;
  width: 483px;
  letter-spacing: 1px;

  .modal-input {
    border: 1px solid #999;
  }
}
.modal-form {
  textarea {
    padding: 8px 14px;
    height: 80px;
    border: 1px solid #a0a0a0;
    border-radius: 12px;
  }
}
.profile-file-input {
  width: 100% !important;
  height: 100px !important;
}
@media (max-width: 599px) {
  .modal {
    width: 350px;
  }
  .modal-buttons {
    flex-direction: column;
  }
}
@media (max-width: 374px) {
  .modal {
    width: 300px;
  }
}
