.navbar-mobile {
  padding: 24px 20px;
  border-radius: 18px;
  color: white;
  z-index: 10;
  margin-bottom: 24px;

  .navbar-mobile-name {
    p {
      margin: 0;
      max-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }
  }

  & .navbar-mobile-avatar-img {
    border-radius: 9px;
    border: 1px solid var(--Purple-2);
  }

  a {
    width: 100%;
    text-decoration: none;
  }

  a:hover {
    background-color: var(--hover-background-color);
  }
  .presence-arrow {
    position: absolute;
    right: 14px;
    top: 12px;
    padding: 8px;
    transition: all 0.5s ease-in-out;
  }
  .arrow-hidden {
    transform: rotate(180deg);
  }

  .arrow-visible {
    transform: rotate(0deg);
  }
  .icon-container {
    width: 24px;
    height: 24px;
  }

  .icon-container svg:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
  }
  .icon-container svg:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 2px;
    top: 2px;
  }

  .icon {
    transition: opacity 0.5s ease-in-out;
  }

  .icon-hidden {
    opacity: 0;
    pointer-events: none;
  }

  .icon-visible {
    opacity: 1;
    pointer-events: all;
  }
}

.navbar-mobile-invisible {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s ease-in-out, opacity 0.3s ease-in-out;
  position: absolute;
  border-radius: 0 0 18px 18px;
}

.navbar-mobile-visible {
  max-height: 700px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
  position: absolute;
  border-radius: 0 0 18px 18px;
}
.presence-section {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.presence-section-visible {
  max-height: 100px;
  opacity: 1;
}

@media (min-width: 1301px) {
  .navbar-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-mobile-name {
    p {
      max-width: 250px !important;
    }
  }
}
@media (max-width: 500px) {
  .navbar-mobile-name {
    p {
      max-width: 180px !important;
    }
  }
}
@media (max-width: 370px) {
  .navbar-mobile-name {
    p {
      max-width: 120px !important;
    }
  }
}
