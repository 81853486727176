.calendar {
  padding: 16px 10px;
  border-radius: 12px;
  background: var(--White);
  max-width: 800px;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);

  &.over {
    position: absolute;
    z-index: 100;
    background-color: white;
  }
}

.calendar-navigation {
  padding: 0 5%;
}

.date-switcher {
  height: 42px;
}

.date-switcher-title {
  letter-spacing: 0.96px;
  color: var(--Black);
  line-height: 20px;
}

.date-switcher {
  width: 50%;
}

.arrow-button {
  height: 100%;
  & button {
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.date-selector-header {
  padding: 2px 8px;
  & p {
    color: var(--Black);
    line-height: 12px;
    letter-spacing: 0.36px;
  }
}

.calendar-day-select {
  width: 40px;
  height: 40px;
  padding: 10px;

  &.selected {
    background-color: var(--Purple-2);
    border-radius: 100px;
  }

  &.between {
    border-radius: 100px;
    background: var(
      --Foundation-Colors-Background---Light-mode,
      linear-gradient(180deg, #f5f5f5 0%, #f2ebf5 100%)
    );
  }

  &.selected p {
    color: #fff;
  }

  &.inactive p {
    color: var(--Gray-6);
  }

  & p {
    color: var(--Black);
    text-align: center;
    font-style: normal;
    line-height: 20px;
  }
}
