.add-employee-page {
  overflow-y: auto;
  height: 100%;
}

.add-employee-page::-webkit-scrollbar {
  display: none;
}

.add-employee-search-buttons {
  padding: 12px;
  border-radius: 24px;
  box-shadow: 3px 5px 10px -2px rgba(13, 10, 44, 0.15);
}

.add-employee-search {
  height: 48px;
  padding: 1px 14px;
  border: 1px solid var(--Siva, #999);
  width: 100% !important;
}

.add-employee-workplace-unit {
  width: 287px !important;
}

.add-employee-form {
  justify-content: flex-end;
  padding: 24px;
  border-radius: 24px;
  box-shadow: 3px 5px 10px -2px rgba(13, 10, 44, 0.15);
}

.add-employee-form-content-column {
  height: fit-content;
}

.add-employee-form-input-wrapper {
  padding: 0;
  width: 100% !important;
}

.add-employee-form-input-wrapper input {
  border-radius: 12px;
  padding: 1px 14px;
  border: 1px solid #999;
}

.add-employee-buttons {
  align-self: stretch;
  justify-content: flex-end;
}

.country-code {
  height: 48px;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  border-radius: 12px;
  padding: 0 !important;
  max-width: 120px !important;
}
.autocomplete-container input {
  border: 1px solid #999;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 1px 14px;
}

.phone-modal-input {
  width: 100% !important;
  border: 1px solid #999;
}

@media (max-width: 699px) {
  .add-employee-form-content {
    flex-direction: column;
  }
}
