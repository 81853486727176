.input-app {
  padding: 1px 14px;
  transition: all 0.2s ease-in-out;

  &.focus {
    border: 1px solid black;
  }

  &.error {
    border: 1px solid red;

    & input {
      border: none !important;
    }
  }

  input {
    border: none;
    line-height: 20px;
    padding: 0;

    &:focus {
      outline: none;
    }
  }
  .dropdown-options {
    z-index: 10;
    width: 100%;
    top: 47px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    // overflow-y: auto;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      line-height: 20px;
      padding: 8px 14px;
      border: 1px solid #505050;
      border-top: 1px solid transparent;
    }
    .last-li {
      border-radius: 0 0 12px 12px;
    }
  }
  .dropdown-options.open {
    max-height: 300px;
    transition: max-height 0.2s ease-in-out 0.15s;
  }
  .arrow-open {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .arrow-closed {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  input[type="file"] {
    display: none;
  }
  .file-label {
    font-size: 16px;
    color: #666;
    user-select: none;
    transition: 0.3s all;
  }

  textarea {
    border: none;
    line-height: 20px;
    padding: 0;
    height: 100%;
    width: 100%;
    padding: 8px 0;

    &:focus {
      outline: none;
    }
  }
}
.custom-file-input {
  border: 2px dashed #999;
  border-radius: 12px;
  padding: 14px 16px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
  width: 300px;
  transition: 0.3s all;
}

.custom-file-input:hover {
  border-color: #aaa;
  .file-label {
    // color: #000;
  }
}

.custom-file-input input[type="file"] {
  display: none;
}

.custom-file-input .file-label {
  font-size: 16px;
  color: #666;
  user-select: none;
}

.custom-file-input .file-name {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}

.custom-file-input .upload-icon {
  margin-right: 10px;
  color: #666;
}

.custom-file-input.focus {
  border-color: #000;
}
