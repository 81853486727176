.button-app {
  border-radius: 12px;
  transition: 0.3s all;

  &.purple {
    background-color: var(--Purple-2);
    color: #fff;
    border: 2px solid transparent;

    &:hover {
      background-color: var(--Purple-3);
    }

    &:active {
      background-color: var(--Purple-2);
    }
    &.disabled {
      border: 2px solid transparent !important;
      background-color: var(--Gray-2) !important;
      & p {
        color: var(--Gray-4) !important;
      }
    }
  }

  &.light-purple {
    border: 2px solid transparent;
    &.active {
      border: 2px solid var(--Purple-2);
    }
    &.disabled {
      border: 2px solid transparent !important;
      background-color: var(--Gray-2) !important;
      & p {
        color: var(--Gray-4) !important;
      }
    }
  }

  &.transparent {
    border: 2px solid var(--Purple-2);
    color: var(--Purple-2);

    &:hover {
      border: 2px solid var(--Purple-3);
      color: var(--Purple-3);
    }

    &:active {
      border: 2px solid var(--Purple-2);
      color: var(--Purple-2);
    }

    &.disabled {
      border: 2px solid var(--Gray-2) !important;
      color: var(--Gray-2) !important;
    }
  }
}

.button-folder {
  border-radius: 12px;
  box-shadow: 3px 5px 10px -2px rgba(13, 10, 44, 0.15);
  padding: 22px 0;
  max-width: 272px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:active {
    color: var(--active-color);
    background-color: var(--active-background-color);
  }

  p {
    margin: 0;
    line-height: 20px;
  }
}
.button-check-in {
  padding: 6px 24px;
  border-radius: 12px;
  max-width: 288px;
  transition: 0.3s all;

  .button-check-in-img {
    border-radius: 8px;
    padding: 20px;
  }

  p {
    margin: 0;
    line-height: 20px;
  }

  &.active {
    background-color: var(--Purple-7);
  }
}
.documents {
  .documents-btn {
    flex: 1;
  }
}
@media (max-width: 1023px) {
  .button-app {
    p {
      font-size: 12px;
      line-height: 18px !important;
    }
  }
  .button-folder {
    padding: 16px 0 !important;

    svg {
      width: 28px;
      height: 28px;
    }
    p {
      font-size: 12px !important;
    }
  }
  .button-check-in {
    .button-check-in-img {
      padding: 6px;
    }
  }
}
@media (max-width: 600px) {
  .button-folder {
    padding: 11px 0 !important;
  }
  .button-check-in {
    padding: 12px 0;
    img {
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 12px;
    }
    .number {
      font-size: 16px;
    }
    .button-check-in-img {
      padding: 6px;
    }
  }
}

.active-border {
  border: 2px solid var(--Purple-2);
}
