.toast {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255, 91, 91);
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

// Animation to hide the toast after 5 seconds
@keyframes hideToast {
  0% {
    opacity: 1;
    visibility: visible;
  }
  90% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.toast.show {
  animation: hideToast 5s forwards;
}
