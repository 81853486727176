.on-off-buttons-selector{
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;

    border-radius: 8px;

    & > .on-off-button{
        display: flex;
        width: 80px;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        transition: .2s all;

        border-radius: 8px;
        border: 1px solid var(--Siva, #999);

        &:hover > p{
        }

        & > p{
            color: var(--Siva, #999);
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            text-transform: uppercase;
            margin: 0;
            transition: .2s all;
        }
    }
}
.on-off-button-active{
    border-radius: 8px;
    background: #FFF;
    border: 0 !important;

    box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);

& > p{
    color: var(--Ljubicasta, #B155D9) !important;
    font-weight: 700 !important;
}
}