/* Input polje */
.date-input {
  &.over {
    position: relative;
    width: 300px;
  }
}
.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.input-wrapper input {
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  height: 46px;
  border-radius: 12px;
}
.input-wrapper .calendar-icon {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
