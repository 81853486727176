.dropdown {
  height: 80px;
  position: relative;
}

.dropdown-input {
  height: 48px;
  border: 1px solid #999;
  padding: 1px 14px;
  border-radius: 12px;
}

.dropdown-input.error {
  border: 1px solid red;
}

.dropdown-input input {
  border: none;
  line-height: 20px;
}

.dropdown-menu {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 10;
  padding: 1px 0;
  border: 1px solid #999;
  border-radius: 12px;
  overflow-y: auto;
  max-height: 220px;
}

.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.dropdown-option {
  padding: 8px 14px;
  border-bottom: 1px solid #505050;
}

.dropdown-option:hover {
  background-color: #f2ebf5;
}
