:root {
  --Gray: #999;
  --Gray-1: #7a7a7a;
  --Gray-2: #818181;
  --Gray-3: #f5f5f5;
  --Gray-4: #c8c8c8;
  --Gray-5: #e0e0e0;
  --Gray-6: #505050;
  --Black: #1f2025;
  --Green: #ceeacf;
  --Green-2: #d6fed8;
  --Green-3: #66ca70;
  --Green-4: #d6fed8;
  --Yellow: #fffa8f;
  --Red: #ffbfbb;
  --Purple-1: #9860b1;
  --Purple-2: #883cef;
  --Purple-3: #9549ff;
  --Purple-4: #744389;
  --Purple-5: #b784fc;
  --Purple-6: #e2ceea;
  --Purple-7: #f2ebf5;
  --Purple-8: #5707b1;
  --Green: #ceeacf;
  --Red-2: #fe7474;
  --Red-3: #ffeeed;
  --Orange: #f19959;
  --Yellow-2: #f6ee37;
  --Yellow-3: #fefdd4;
  --blue: #c3cdff;
}
.red-text {
  color: var(--Red-2);
}
.orange-text {
  color: var(--Orange);
}
.green-text {
  color: var(--Green-2);
}

.Grotesk-500 {
  // font-family: "Roc Grotesk 500", sans-serif;
  // letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.Grotesk-400 {
  // font-family: "Roc Grotesk 400", sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.red {
  background-color: var(--Red);
}

.green {
  background-color: var(--Green-2);
}

.yellow {
  background-color: var(--Yellow);
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex-direction-row {
  display: flex;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1-0-0 {
  flex: 1 0 0;
}

.gap-96 {
  gap: 96px;
}

.gap-49 {
  gap: 49px;
}

.gap-48 {
  gap: 48px;
}

.gap-32 {
  gap: 32px;
}

.gap-24 {
  gap: 24px;
}

.gap-20 {
  gap: 20px;
}

.gap-17 {
  gap: 17px;
}

.gap-15 {
  gap: 15px;
}

.gap-14 {
  gap: 14px;
}

.gap-12 {
  gap: 12px;
}

.gap-10 {
  gap: 10px;
}

.gap-9 {
  gap: 9px;
}

.gap-8 {
  gap: 8px;
}

.gap-6 {
  gap: 6px;
}

.gap-5 {
  gap: 5px;
}

.gap-4 {
  gap: 4px;
}

.gap-1 {
  gap: 1px;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-self: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.white-text {
  color: white;
}

.gray-3 {
  color: var(--Gray--3);
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration-line: underline;
}

.font-32 {
  font-size: 32px;
}

.font-24 {
  font-size: 24px;
}

.font-20 {
  font-size: 20px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.weight-500 {
  font-weight: 500;
}

.weight-400 {
  font-weight: 400;
}

.shadows-2-2-8 {
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.shadows-0-0-8 {
  box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.15);
}

.shadows-0-9-20 {
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.25);
}

.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (max-width: 1023px) {
  .font-16 {
    font-size: 16px;
    line-height: 20px;
  }
}
