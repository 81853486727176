.image-cropper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20;
}
.drop-zone-container {
  width: 100px;
  height: 100px;
  /* padding: 20px; */
  box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);
  border-radius: 12px;
}
.drop-zone-image-preview {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.drop-zone-image-preview img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;
}
.cropper-x {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}
.ReactCrop__crop-selection {
}
.text-gray-400 {
  color: rgb(156 163 175);
  font-weight: 400;
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}
.ReactCrop__child-wrapper {
  max-height: inherit;
}
.ReactCrop *,
.ReactCrop *:before,
.ReactCrop *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ReactCrop:not(.ReactCrop--disabled) .ReactCrop__crop-selection {
  -ms-touch-action: none;
  touch-action: none;
}
.ReactCrop--no-animate .ReactCrop__crop-selection {
  outline: 1px dashed white;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 12px;
}
.ReactCrop__child-wrapper {
  max-height: inherit;
}
.ReactCrop--no-animate .ReactCrop__crop-selection {
  outline: 1px dashed white;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  cursor: move;
  -webkit-box-shadow: 0 0 0 9999em #00000080;
  box-shadow: 0 0 0 9999em #00000080;
  width: 150px;
  height: 150px;
}
.ReactCrop *,
.ReactCrop *:before,
.ReactCrop *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ReactCrop .ord-n,
.ReactCrop .ord-e,
.ReactCrop .ord-s,
.ReactCrop .ord-w {
  display: none;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
  position: absolute;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-handle:after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #0003;
  border: 1px solid rgba(255, 255, 255, 0.7);
  outline: 1px solid transparent;
}
.ReactCrop__drag-handle:after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #0003;
  border: 1px solid rgba(255, 255, 255, 0.7);
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop__drag-handle {
  position: absolute;
}

.p-4 {
  padding: 1rem;
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  /* background-color: rgba(17 24 39 0.4); */
}
.backdrop-blur-sm {
  /* background-color: rgba(129, 129, 129, 0.5); */
}
.min-h-screen {
  min-height: 100vh;
}
.z-10 {
  z-index: 10;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.inset-0 {
  inset: 0px;
}
.fixed {
  position: fixed;
}
.overflow-y-auto {
  overflow-y: auto;
}
.w-screen {
  width: 100vw;
}
.text-center {
  text-align: center;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.p-1 {
  padding: 0.25rem;
}
.justify-center {
  justify-content: center;
}
.min-h-full {
  min-height: 100%;
}
.flex {
  display: flex;
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}
.text-left {
  text-align: left;
}
.rounded-2xl {
  border-radius: 1rem;
}
.min-h-\[60vh\] {
  min-height: 60vh;
}
.rounded-md {
  border-radius: 0.375rem;
}
.inline-flex {
  display: inline-flex;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
.width-80 {
  width: 80%;
}
.justify-between {
  justify-content: space-between;
}
.modal-btn {
  height: 44px;
  width: 44px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background: #fff;
  display: inline-flex;
  padding: 12px;
  top: 16px;
  right: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.w-full {
  width: 100%;
}
.block {
  display: block;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.bg-sky-500 {
  --tw-bg-opacity: 1;
  /* background-color: rgb(14 165 233 / var(--tw-bg-opacity)); */
}
.ReactCrop svg {
  display: none;
}
