.profile-page {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: 0;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.personal-information {
  padding: 61px 24px;

  border-radius: 24px;
  background-color: var(--Purple-2);

  .profile-picture {
    border-radius: 12px;
    border: 4px solid #fff;
    background: #e2ceea;
    min-width: 130px;
    height: 130px;
    aspect-ratio: 1/1;
  }
  .line {
    height: 100%;
    background-color: var(--Gray--3);
    width: 1px;
  }
  p {
    max-width: 180px;
  }
}

.employment-timeline {
  border-radius: 24px;
  background: #fff;
  padding: 24px;

  color: var(--Black);

  .line {
    height: 1px;
    opacity: 0.11;
    background-color: #696969;
  }
  p {
    width: 50px;
    flex: 1;
  }
  .employment-timeline-title {
    width: 210px;
    max-height: 20px;
  }
  .employment-timeline-position {
    width: 210px;
  }
}
.profile-details {
  padding: 30px 45px;
  background-color: white;
  border-radius: 24px;
}

.profile-page-search {
  border: 1px solid var(--Gray-2);
  flex: 1;
  max-width: 400px;
}
.profile-details-btn-search {
  gap: 24px;
}
.profile-page-row {
  padding: 10px 48px;
  border-radius: 12px;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);

  .profile-page-row-img {
    background-color: var(--Purple-6);
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }

  img {
    border: 2px solid var(--Green-2);
    border-radius: 135px;
  }
  p {
    flex: 1;
    width: 100px;
    text-align: center;
  }
  .profile-page-row-status {
    width: 151px;
    height: 27px;
    border-radius: 8px;
    opacity: 0.8;
  }
}
.team-see-more {
  img {
    width: 7px;
    height: 14px;
  }
}
.table-header {
  padding: 10px 48px 10px 104px;
  p {
    flex: 1;
    width: 100px;
    text-align: center;
  }
}
.other-info-btn {
  justify-content: end;
}
.other-info-row {
  border-radius: 12px;
  padding: 10px 32px;

  p {
    width: 300px;
  }
}

.arrow-hidden {
  transform: rotate(180deg);
  transition: all 0.3s ease-in;
}
.arrow-visible {
  transform: rotate(0deg);
  transition: all 0.3s ease-out;
}

.transition-wrapper {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out 0.1s;

  &.expanded {
    max-height: 400px;
    opacity: 1;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in 0.1s;
  }
}

.training-card {
  padding: 30px 45px;
}

.table-header-training {
  padding: 10px 48px;
  p {
    width: 100px;
    flex: 1;
    text-align: center;
    line-height: 20px;
  }
}

@media (max-width: 1350px) {
  .employment-timeline {
    .employment-timeline-position {
      width: 175px;
    }
  }
}
@media (max-width: 1300px) {
  .personal-information {
    gap: 24px;

    p {
      max-width: 160px;
    }

    .personal-information-container {
      gap: 5px;

      .column {
        width: 170px !important;
      }
    }
  }
}
@media (max-width: 1023px) {
  .personal-information-employment {
    flex-direction: column;
  }
  .personal-information {
    width: 100%;
    gap: 49px;
    justify-content: space-between;

    p {
      max-width: 200px;
    }

    .personal-information-container {
      gap: 48px !important;
      .column {
        width: 200px !important;
      }
    }
  }
  .employment-timeline {
    .employment-timeline-title {
      width: 400px;
    }
    .employment-timeline-dates {
      width: 180px !important;
      p {
        text-align: center;
      }
    }
    .employment-timeline-row {
      gap: 12px;
      div {
        div {
          gap: 24px;
        }
      }
      p {
        max-width: 250px;
      }
    }
  }
  .profile-page-btn {
    width: 106px !important;
    height: 44px;
  }
  .profile-details {
    padding: 30px 24px;
  }
  .training-card {
    padding: 10px 24px;
  }
}
@media (max-width: 799px) {
  .personal-information {
    p {
      max-width: 167px;
    }
    .personal-information-container {
      gap: 8px !important;
    }
  }
  .employment-timeline {
    .employment-timeline-row {
      div {
        div {
          gap: 24px;
        }
      }
      p {
        max-width: 200px;
      }
    }
  }
  .profile-page-row {
    p {
      font-size: 12px;
    }
  }
  .table-header {
    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .table-header-training {
    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
@media (max-width: 760px) {
  .hidden-sm {
    display: none;
  }
}
@media (max-width: 699px) {
  .personal-information {
    gap: 24px;
    .personal-information-container {
      .column {
        width: 140px !important;
      }
    }
    p {
      font-size: 14px;
      max-width: 140px;
    }
    .personal-information-name {
      font-size: 18px;
    }
  }
  .employment-timeline {
    .employment-timeline-row {
      div {
        div {
          gap: 12px !important;
        }
      }
    }
  }
  .profile-details-btn-search {
    flex-direction: column;
    gap: 12px;
  }
  .profile-details-buttons {
    width: 100%;
  }
  .profile-page-search {
    max-width: 100%;
    width: 100% !important;
    flex: none;
  }
  .profile-page-btn {
    flex: 1;
  }
  .profile-details {
    padding: 24px 18px;
  }
  .documents-buttons {
    flex-direction: column;
  }

  .training-card {
    padding: 10px 18px;
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.animated {
  animation: fadeIn 0.5s ease-in-out; /* Dodajemo animaciju fadeIn */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 549px) {
  .personal-information {
    padding: 32px 24px;
    flex-direction: column;
    .personal-information-picture-name {
      flex-direction: row;
      justify-content: space-between;
      .personal-information-email-name {
        width: 157px;
      }
    }
    p {
      width: 157px;
    }
    .personal-information-container {
      justify-content: space-between;
      .column {
        width: 157px !important;
      }
    }
  }
  .employment-timeline {
    .employment-timeline-title {
      width: 250px;
    }
  }
  .employment-timeline-row {
    flex-direction: column;
    gap: 20px;
    p {
      font-size: 14px;
    }
    div {
      width: 100%;
    }
    .employment-timeline-dates {
      width: 100% !important;
      p {
        max-width: 62px;
      }
    }
  }
  .profile-page-row {
    padding: 16px 12px;
    gap: 12px;
    justify-content: space-between;

    // & div:nth-child(2) {
    //   max-width: calc(100% - 120px);
    // }

    .profile-page-row-status {
      width: 30px;
      height: 20px;
      animation: slideInFromLeft 0.8s ease-in-out forwards;
    }
    .profile-page-row-text {
      gap: 12px;
    }
  }
  .superiors-btn {
    gap: 24px;
    .button-app {
      flex: 1;
      max-width: 168px;
    }
  }
  .team-see-more {
    width: 100% !important;
  }
  .profile-page-search {
    height: 36px !important;
  }
  .profile-details {
    gap: 24px;
  }
  .table-header {
    padding: 10px 12px 10px 54px;
  }
  .profile-title {
    font-size: 14px !important;
  }
  .other-info-row {
    padding: 10px 18px;

    p {
      width: 200px;
      font-size: 14px;
    }
  }
  .table-header-training {
    padding: 10px 12px;
  }
}
@media (max-width: 399px) {
  .personal-information {
    .personal-information-picture-name {
      .personal-information-email-name {
        width: 140px;
      }
    }
    p {
      width: 140px;
    }
    .personal-information-container {
      .column {
        width: 130px !important;
      }
    }
  }
  .employment-timeline-row {
    flex-direction: column;
    gap: 20px;
    p {
      font-size: 14px;
    }
    div {
      width: 100%;
    }
    .employment-timeline-dates {
      width: 100% !important;
      p {
        max-width: 62px;
      }
    }
  }
  .profile-page-row {
    .profile-page-row-text {
      & p:nth-child(2) {
        display: none;
      }
    }
  }
  .hidden-xs {
    display: none;
  }
  .superiors-btn {
    .button-app {
      max-width: 140px;
      max-height: 44px;
    }
  }
  .other-info-row {
    p {
      width: 160px;
    }
  }
}
@media (max-width: 350px) {
  .personal-information {
    .personal-information-picture-name {
      flex-direction: column;
      .personal-information-email-name {
        width: 130px;
      }
      p {
        width: 240px !important;
        max-width: 240px !important;
      }
    }
    p {
      width: 240px !important;
      max-width: 240px !important;
    }
    .personal-information-container {
      flex-direction: column;
      align-items: center;
      .column {
        width: 100% !important;
        div {
          width: 100% !important;
        }
      }
    }
  }
  .employment-timeline-row {
    flex-direction: column;
    gap: 20px;
    p {
      font-size: 14px;
    }
    div {
      width: 100%;
    }
    .employment-timeline-dates {
      width: 100% !important;
      p {
        max-width: 62px;
      }
    }
  }

  .other-info-row {
    p {
      width: 130px;
    }
  }
}
