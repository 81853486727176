.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--Gray-3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0.7;
}
.loader {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--Purple-1);
  border-right: 1.1em solid var(--Purple-1);
  border-bottom: 1.1em solid var(--Purple-1);
  border-left: 1.1em solid var(--Purple-3);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
