.filter {
  position: relative;
  .filter-options {
    position: absolute;
    top: 0;
    right: 38px;
    border-radius: 12px;
    border: 2px solid #999;
    width: 155px;
    gap: -1px;

    background: var(--Bela, #fff);

    opacity: 0;
    transform: translateX(8%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    &.visible {
      opacity: 1;
      transform: translateX(0);
    }
    .filter-option {
      padding: 8px 14px;
      border-radius: 12px;

      background: var(--Bela, #fff);

      &:hover {
        color: var(--Black);
      }
    }
  }
}
.filter-line {
  width: 100%;
  height: 1px;
  background-color: var(--Gray);
}

@media (max-width: 599px) {
  .filter-picture {
    width: 24px;
  }
}
