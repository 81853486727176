#root {
  height: 100vh;
  width: 1440px;
  max-width: 1440px !important;
}
$app-navbar-width: 10%;
$app-navbar-hover-width: 18%;
$app-content-width: 90%;
$app-content-hover-width: 82%;
$padding: 20px;

@font-face {
  font-family: "Roc Grotesk 500";
  src: url("../assets/fonts/Fontspring-DEMO-rocgrotesk-medium.otf")
    format("opentype");
}
@font-face {
  font-family: "Roc Grotesk 400";
  src: url("../assets/fonts/Fontspring-DEMO-rocgrotesk-light.otf")
    format("opentype");
}

.app-container {
  display: flex;
  justify-content: space-between;
  height: 95%;
  width: 100%;
  margin-top: 30px;
}

.navbar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  flex: 0 0 calc($app-navbar-width - $padding);
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
  border-radius: 20px;
  padding: 24px 0 24px 0;
  transition: flex-basis 0.3s ease, padding-top 0.3s ease;

  &:hover {
    flex-basis: calc($app-navbar-hover-width - $padding);
  }

  .navbar-avatar {
    display: flex;
  }

  .navbar-avatar-items {
    width: 50px;
    height: 48px;
    display: flex;
    transition: width 0.3s ease;
  }

  &:hover .navbar-avatar-items {
    width: 200px;
  }

  .presence-arrow {
    position: absolute;
    right: 14px;
    top: 20px;
    overflow: hidden;
    visibility: hidden;
  }

  &:hover .presence-arrow {
    overflow: auto;
    visibility: visible;
  }

  .navbar-avatar-img {
    border-radius: 8px;
    background-color: #e2ceea;
    transition: height 0.3s ease, width 0.3s ease;
  }

  .navbar-avatar-text {
    height: 100%;
    padding: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
  }

  .navbar-avatar p {
    width: 200px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    margin: 0;
    color: #1f2025;
    font-style: normal;
    line-height: 20px;
  }

  &:hover .navbar-avatar p {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .navbar-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5 * $padding;
  }

  .navbar-menu-items {
    display: flex;
    gap: 10px;
  }

  .navbar-menu-items ul {
    padding: 0;
    margin: 0;
    list-style: none;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
  }

  .navbar-menu-items a {
    height: 100%;
    width: 50px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: width 0.3s ease, background-color 0.5s ease;
  }

  .navbar-menu-items a.active {
    font-weight: 600;
  }

  .navbar-menu-items a:hover {
    // background-color: #f1f0f0;
    background-color: var(--hover-background-color);
  }

  .navbar-menu-items p {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    margin: 0;
    color: #1f2025;
    font-style: normal;
    line-height: 20px;
  }

  &:hover .navbar-menu-items p {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  &:hover .navbar-menu-items a {
    width: 200px;
  }
}

.content {
  flex: 0 0 calc($app-content-width - $padding);
  transition: flex-basis 0.3s ease;
  padding: 0 20px 0 0;
  overflow-y: auto;

  .navbar:hover ~ & {
    flex-basis: calc($app-content-hover-width - $padding);
  }
}

@media (max-width: 1400px) {
  .navbar {
    &:hover {
      flex-basis: calc($app-navbar-hover-width + 10px);
    }
  }
}

@media (max-width: 1300px) {
  .app-container {
    // height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
  .content {
    width: 100%;
    flex-basis: auto;
    padding: 24px 20px 2px 20px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .navbar {
    display: none;
  }
}
