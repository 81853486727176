.settingspage-main{
    width: 100%;
    height: fit-content;

    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 48px;
    flex: 1 0 0;
}
.settings-user-preference-selector{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
}
.settings-language-selector{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.languages-conteiner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 12px;
    background: var(--Siva--3, #F5F5F5);

    & > .language{
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        cursor: pointer;
        transition: .4s all;

        border-radius: 12px;

        & > p{
            flex: 1 0 0;

            color: var(--Black, #1F2025);
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Outfit";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 125% */
            margin: 0;
        }
    }
}
.language-selector-title{
    color: var(--Black, #1F2025);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    text-transform: capitalize;
}
.active-language{
    background: #FFF;

    box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);
}

.settings-notifications-selector{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

}
.notification{
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    & > p{
        color: var(--Black, #1F2025);
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        margin: 0;
    }
}


.theme-selector{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.theme-selector-title{
    color: var(--Black, #1F2025);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    text-transform: capitalize;
}
.theme-selector-buttons{
    display: flex;
    align-items: flex-start;
    gap: 12px;

    border-radius: 12px;
}
.theme-selector-button{
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: .4s all;

    border-radius: 8px;

    & > img{
        width: 40px;
        height: 40px;
    }
    & > p{
        color: var(--Black, #1F2025);
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        margin: 0;
    }
}
.theme-selector-button-active{
    background: #FFF;

    /* karica na karticu */
    box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);
}
.settings-password-change-section{
    display: flex;
    width: 496px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    & > p{
        color: var(--Black, #1F2025);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        text-transform: capitalize;
        margin: 0;
    }
}
.password-change-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    
}
.password-change-inputs-conteiner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    & > p{
        align-self: stretch;

        color: var(--Crvena, #E45757);
        font-feature-settings: 'clig' "off", 'liga' "off";
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        margin: 0;
    }
}
.password-change-input{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 12px;
    background: #FFF;

    /* karica na karticu */
    box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);

    & > input{
        border: none;
        width: 100%;

        color: var(--siva-1, #7A7A7A);
        // text-align: center;
        font-feature-settings: 'clig' "off", 'liga' "off";
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        outline: none;
    }

    & > img{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
.password-change-button{
    display: flex;
    width: 212px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: .4s all;

    border-radius: 12px;
    background: var(--Ljubicasta--1, #CB99E0);

    &:hover, &:hover > p{
        background: var(--Ljubicasta, #B155D9);
    }

    & > p{
        color: #FFF;
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        text-transform: capitalize;
        margin: 0;
        transition: .4s all;

        border-radius: 12px;
        background: var(--Ljubicasta--1, #CB99E0);

    }
}
.input-problem{
    border: 1px solid var(--Crvena, #E45757) !important; 
}
.password-changed-successfully-background{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(114, 100, 100, .1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.password-changed-successfully{
    // transition: transform(-50%, -50%);
    display: inline-flex;
    padding: 48px;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    border-radius: 20px;
    background: #FFF;


    & > img{
        width: 80px;
        height: 80px;
        cursor: pointer;
    }
    & > p{
        color: var(--Black, #1F2025);
        font-family: "Outfit";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        text-transform: uppercase;
    }
}