.attendance-page {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .attendance-page-buttons {
    border-radius: 24px;
    padding: 24px;

    & > div {
      flex: 1;
    }
  }

  .attendance-check-btn {
    background-color: var(--Purple-7);
    border: 1px solid var(--Purple-2);
  }

  .attendance-error-btn {
    background-color: var(--Red-3);
    border: 1px solid var(--Red-2);
  }

  .attendance-check-out-btn {
    background-color: var(--Yellow-3);
    border: 1px solid var(--Yellow-2);
  }

  .attendance-holiday-btn {
    background-color: var(--Green-4);
    border: 1px solid var(--Green-3);
  }
}
.attendance-content {
  padding: 24px;
  border-radius: 24px;
  height: 300px;
  flex-grow: 1;

  .attendance-content-buttons {
    border-radius: 12px;
  }

  .attendance-btn {
    border: 2px solid transparent;
    flex: 1;
    padding: 14px 12px !important;

    &:hover {
      border: 2px solid transparent;
    }

    &.active {
      box-shadow: 1px 1px 3.3px -0.5px rgba(31, 32, 37, 0.24);
    }
  }

  .filter-panel {
    & > div:nth-child(1) {
      flex: 1;
    }
    & .attendance-page-search {
      border: 1px solid var(--Gray-2);
      flex: 1;
      width: 100% !important;
    }
    .workplace-unit {
      border: none;
      flex: 1;
      box-shadow: 1px 1px 3.3px -0.5px rgba(31, 32, 37, 0.24);
    }
    .kalendar {
      height: 100%;
      width: 93px;
      background-color: rgb(209, 209, 209);
      border-radius: 12px;
      padding: 16px;
    }
  }
}
.workHours-tracker {
  overflow-y: auto;
  // max-height: 440px; temporary expanding table
  padding: 6px 0;

  &::-webkit-scrollbar {
    z-index: 0 !important;
    width: 1px;
    // display: none;
  }
  .workHours-tracker-row {
    height: 102px;
    min-height: 102px;
    width: calc(100% - 6px);
    padding: 8px 24px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px -0.5px rgba(13, 10, 44, 0.24);
    z-index: 5;

    .workHours-tracker-row-picture {
      width: fit-content;
      height: fit-content;
      border: 2px solid var(--Purple-2);
      background-color: var(--Purple-6);
      border-radius: 100%;
    }

    .workHours-tracker-row-hours {
      & > div {
        flex: 1;
      }
    }
    .workHours-tracker-row-name {
      width: 150px;
    }

    .daily-graph-container {
      max-width: 680px;
    }
    .apexcharts-inner {
      transform: translate(16px, 22px) !important;
    }
  }
}

.workHours-editor {
  padding: 12px 4px;

  .workHours-editor-gauge-buttons {
    width: 364px;

    .workHours-editor-btn {
      height: 54px;
      width: 100% !important;
    }
  }

  .workHours-editor-data {
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1 0 0;

    .workHours-editor-dataTable {
      border-radius: 8px;
      padding: 12px;
      height: 330px;

      .timetable-main {
        gap: 12px;

        .timetable-content {
          max-height: 276px;
          overflow: auto;
          gap: 8px;
        }

        .timetable-row {
          margin: 3px 0 0 0;
          padding: 8px 24px;
        }
      }
    }
  }
}

.add-action-modal {
  width: 364px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);
}

.add-action-modal-time {
  width: 100% !important;
  border: 1px solid #999;
}

.add-action-modal-textarea {
  border: 1px solid #999;
  width: 100% !important;
  height: 80px !important;
}

.employee-person {
  border-radius: 8px;
  background-color: #fff;
  padding: 24px;

  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
}

.cumulative-view {
  border-radius: 8px;
  background: #fff;
  padding: 24px;

  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);
  // overflow-x: auto;
  overflow: hidden;
}

.cumulative-view-content {
  overflow-y: scroll;
  overflow-x: hidden;
  // max-height: 344px;
  width: 100%;
  padding-bottom: 20px;
}

.cumulative-view::-webkit-scrollbar,
.cumulative-view-content::-webkit-scrollbar {
  display: none;
}

.cumulative-view-header {
  width: 1176px;
  margin-right: 3px;
  margin-left: 3px;
}

.cumulative-view-header-employee {
  width: 200px;
  text-align: center;
}

.cumulative-view-header-item {
  text-align: center;
}

.cumulative-view-row {
  border-radius: 8px;
  padding-left: 10px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 0px 8px -2px rgba(31, 32, 37, 0.24);
}

.cumulative-view-row-content p {
  text-align: center;
}

@media (max-width: 1400px) {
  .workHours-tracker {
    .workHours-tracker-row {
      .daily-graph-container {
        max-width: 600px;
      }
    }
  }
}
@media (max-width: 1300px) {
  .workHours-tracker-row {
    gap: 24px;
  }
}
@media (max-width: 1023px) {
  .workHours-tracker-row {
    height: 134px !important;
    min-height: 134px !important;
    width: calc(100% - 4px) !important;
    position: relative;
    align-items: start;
    justify-content: space-between;
    gap: 0;

    &.unregistered {
      height: 165px !important;
      min-height: 165px !important;

      .daily-graph-container-wrapper {
        top: 100px;
      }
    }

    .daily-graph-container-wrapper {
      position: absolute;
      top: 66px;
      width: calc(100% - 48px);
    }
  }
  .workHours-editor {
    .workHours-editor-gauge-buttons {
      width: 288px;

      .workHours-editor-buttons {
        flex-direction: column;
      }
    }
    .workHours-editor-data {
      .workHours-editor-dataTable {
        .timetable-main {
          .timetable-row {
            padding: 16px 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .workHours-editor {
    flex-direction: column;
    .workHours-editor-gauge-buttons {
      width: 100%;
      .workHours-editor-buttons {
        align-items: center;

        .workHours-editor-btn {
          max-width: 400px;
        }
      }
    }
    .workHours-editor-data {
      width: 100%;
      align-items: center;
      .see-more {
        width: 100% !important;
        max-width: 450px;
      }
    }
  }
  .employee-person {
    padding: 24px 12px;
  }
}
@media (max-width: 699px) {
  .attendance-content {
    .filter-panel {
      flex-direction: column;

      .kalendar {
        flex: 1;
      }
      .filter-panel-add-btn {
        flex: 1;
      }
    }
  }

  .workHours-tracker {
    .workHours-tracker-row {
      padding: 8px 12px;

      .workHours-tracker-row-hours {
        margin-left: 0 !important;
      }

      .daily-graph-container-wrapper {
        width: calc(100% - 24px);
        padding: 0;
      }
    }
  }
}
@media (max-width: 599px) {
  .attendance-page-buttons {
    gap: 0;
    padding: 24px 12px !important;
  }
  .attendance-content {
    padding: 24px 12px;
    .attendance-btn {
      max-width: 33%;
      height: 100%;
      padding: 14px 4px !important;
      p {
        line-height: 12px !important;
        white-space: wrap;
        text-align: center;
      }
    }
  }
}
@media (max-width: 500px) {
  .workHours-tracker-row-name {
    max-width: 150px;
  }
}
@media (max-width: 400px) {
  .workHours-tracker-row-name {
    max-width: 120px;
  }
  .workHours-tracker-row-hours {
    gap: 8px;
  }
}
@media (max-width: 370px) {
  .attendance-content {
    .filter-panel {
      & > div:nth-child(1) {
        flex-direction: column;
      }
      .attendance-page-search {
        padding: 16px 14px !important;
        width: 100% !important;
      }
      .workplace-unit {
        width: 100% !important;
      }
    }
  }
  .workHours-tracker-row {
    width: calc(100%) !important;
  }
  .workHours-tracker-row-name {
    max-width: 80px;
  }
}
