.options-search-list-container {
  min-width: 100px;
  position: relative;
}

.options-search-list-input-wrapper {
  padding: 1px 14px;
  height: 48px;

  border-radius: 12px;
  border: 1px solid var(--Gray);
  background-color: #fff;

  &.list-opened {
    border-radius: 12px 12px 0 0;
  }

  & input {
    border: none;
    height: 100%;
    outline: none;

    color: var(--Black);
    line-height: 20px;
  }
}

.options-search-list-options-wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;

  flex: 1;

  padding: 12px 14px;

  border-radius: 0px 0px 12px 12px;
  background: #fff;
  box-shadow: 2px 2px 8px -2px rgba(13, 10, 44, 0.24);

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
    border-radius: 0 0 12px 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: var(--Gray-4);
  }

  & .line {
    height: 1px;
    opacity: 0.11;
    background-color: var(--Gray-2);
  }

  & .options-search-list-option {
    padding: 5px 0;
    height: 50px;
  }

  & .options-search-list-option:last-child {
    // padding: 5px 0 0 0;
  }

  & p {
    flex: 1;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
}
